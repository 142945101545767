<template>
    <div class="modal-f-t">
        <div class="itme-scroll flex-1">
            <div class="my-time-full">                
                <el-time-picker
                    class="my-time"
                    format="HH:mm"
                    value-format="HH:mm"
                    :placeholder="$t('message.Start time of work')"
                    v-model="value">
                </el-time-picker>
            </div>
        </div>
        <el-row class="btns">
            <el-button @click="submit()" type="success" plain>{{ $t('message.save') }}</el-button>
            <el-button @click="close()"  type="warning" plain>{{ $t('message.close') }}</el-button>
        </el-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import drawer from '@/utils/mixins/includes/drawer';

export default {
    mixins: [drawer],
    props:['start_time'],
    data() {
        return {
            value: "",
        };
    },
    methods: {
        ...mapActions({
            
        }),
        async afterOpen() {
            this.value = this.start_time ? this.start_time : null;            
        },
        submit() {
            if (!this.value) {
                this.$message({
                    message: this.$t('message.Not selected'),
                    type: 'warning',
                    showClose: true
                });
                return;
            }
            
            this.$emit('set-start-time', {
                start_time: this.value,
            })
            this.close();
        }
    },
};
</script>
<style>
.my-time-full {
    display: flex;
    justify-content: center;
    padding-top: 50px;
}
.my-time-full .el-date-editor.el-input {
    width: 600px;
}
.my-time input {
    height: 80px;
    width: 600px;
    margin: auto;
    font-size: 25px;
}
</style>