<template>
    <div class="modal-f-t">
        <div class="itme-scroll flex-1">
            <div class="meto">
                <div class="meto-number-block" v-for="(meto_number, index) in meto_numbers_for_form"
                        :key="index + 'meto_numbers_for_form'">
                    <div class="clasik">
                        <el-input-number
                            v-model="meto_number.current_meto_number"
                            :min="1"
                        ></el-input-number>

                        <div>
                            <el-button class="btn-save3" @click="removeMetoNumber(index)" type="danger" icon="el-icon-delete" ></el-button>
                        </div>
                    </div>
                </div>
                

                <el-button @click="addMetoNumber()" type="success" plain>{{ $t('message.Add') }}</el-button>
            </div>
        </div>
        <el-row class="btns">
            <el-button @click="submit()" type="success" plain>{{ $t('message.save') }}</el-button>
            <el-button @click="close()"  type="warning" plain>{{ $t('message.close') }}</el-button>
        </el-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import drawer from '@/utils/mixins/includes/drawer';

export default {
    mixins: [drawer],
    props:['meto_numbers', 'bant_id', 'form_initial_data', 'sale_product_process_id'],
    data() {
        return {
            num: 1,
            meto_numbers_for_form: [
                {
                    current_meto_number: '',
                }
            ]
        };
    },
    methods: {
        ...mapActions({
            checkMetoNumbersAttachedToBant: "saleChecks/checkMetoNumbersAttachedToBant",
        }),
        handleChange(value) {
            console.log(value);
        },
        async afterOpen() {
            this.meto_numbers_for_form = JSON.parse(JSON.stringify(this.meto_numbers));
        },
        addMetoNumber() {
            this.meto_numbers_for_form.push({
                current_meto_number: '',
            });
        },
        removeMetoNumber(index) {
            if (_.size(this.meto_numbers_for_form)) this.meto_numbers_for_form.splice(index, 1)
        },
        submit() {
            let data = {
                bant_id: this.bant_id,
                meto_numbers: this.meto_numbers_for_form,
                sale_id: this.form_initial_data ? this.form_initial_data.sale_id : null,
                product_id: (this.form_initial_data && this.form_initial_data.sale_product) ? this.form_initial_data.sale_product.product_id : null,
                sale_product_process_id: this.sale_product_process_id,
            }
            this.checkMetoNumbersAttachedToBant(data)
                .then(res => {
                    this.$emit('set-meto-numbers', {
                        meto_numbers: this.meto_numbers_for_form,
                    })
                    this.close();
                }).catch(err => {
                    this.$alert(err);
                });
        },
        afterLeave() {
            this.meto_numbers_for_form = [
                {
                    current_meto_number: '',
                }
            ]
        }
    },
};
</script>

<style>
.meto-number-block {
    text-align: center;
}

.meto-number-block .el-button {
    width: 40px;
    /* height: 40px; */
}
</style>