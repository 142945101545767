<template>
    <div class="modal-f-t">
        <div class="itme-scroll flex-1">
            <div class="my-time-full">                
                <el-time-picker
                    class="my-time"
                    format="HH:mm"
                    value-format="HH:mm"
                    :placeholder="$t('message.Start_time')"
                    v-model="form.start_time">
                </el-time-picker>
            </div>

            <div class="my-time-full">                
                <el-time-picker
                    class="my-time"
                    format="HH:mm"
                    value-format="HH:mm"
                    :placeholder="$t('message.Start_Off')"
                    v-model="form.end_time">
                </el-time-picker>
            </div>

            <div class="switch">
                <el-switch
                    style="display: block"
                    v-model="form.is_for_all"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :active-text="$t('message.For all')">
                </el-switch>
            </div>

            <template v-if="!form.is_for_all">
                <div class="nav-meni-itmes flex-1 my-tir" @click="saleProductProcessDrawer = true">
                    <i class="el-icon-setting left-icon"></i>
                    <div class="name-p">
                        <div class="t-name">{{ (form.sale_product_process && form.sale_product_process.process) ? form.sale_product_process.process.name : $t('message.Not selected') }}</div>
                        <div class="b-name">{{ $t('message.Process') }}:</div>
                    </div>
                </div>

                <div class="nav-meni-itmes flex-1 my-tir" @click="bantDrawer = true">
                    <i class="el-icon-setting left-icon"></i>
                    <div class="name-p">
                        <div class="t-name">{{ form.bant ? form.bant.name : $t('message.Not selected') }}</div>
                        <div class="b-name">{{ $t('message.Bant') }}:</div>
                    </div>
                </div>    
            </template>

            <div class="my-tir">
                <el-input type="textarea" :placeholder="$t('message.Comment')" v-model="form.comment">
                </el-input>
            </div>
        </div>
        <el-row class="btns">
            <el-button @click="submit()" :loading="loading" type="success" plain>{{ $t('message.save') }}</el-button>
            <el-button @click="close()" :loading="loading" type="warning" plain>{{ $t('message.close') }}</el-button>
        </el-row>


        <el-drawer
            class="modal-style"
            :direction="direction"
            :visible.sync="saleProductProcessDrawer"
             :append-to-body="true"
            size="80%"
            ref="saleProductProcessDrawer"
            @closed="drawerClosed('saleProductProcessDrawerChild')" 
            @opened="drawerOpened('saleProductProcessDrawerChild')"
        >
            <sale-product-process-select
                :sale_product_id="sale_product_id"
                :sale_product_process_id="form.sale_product_process_id"
                @set-sale-product-process="setSaleProductProcess"
                drawer="saleProductProcessDrawer" 
                ref="saleProductProcessDrawerChild">
            </sale-product-process-select>
        </el-drawer>

         <el-drawer
            class="modal-style"
            :direction="direction"
            :visible.sync="bantDrawer"
             :append-to-body="true"
            size="80%"
            ref="bantDrawer"
            @closed="drawerClosed('bantDrawerChild')" 
            @opened="drawerOpened('bantDrawerChild')"
        >
            <bant-select
                :bant_id="form.bant_id"
                @set-bant="setBant"
                drawer="bantDrawer" 
                ref="bantDrawerChild">
            </bant-select>
        </el-drawer>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import drawer from '@/utils/mixins/includes/drawer';
import SaleProductProcessSelect from "./includeForSaleDisruption/sale-product-process-select.vue";
import BantSelect from "./includeForSaleDisruption/bant-select.vue";
export default {
    mixins: [drawer],
    props:['sale_check_id', 'sale_product_id'],
    components: {
        SaleProductProcessSelect,
        BantSelect
    },
    data() {
        return {
            form: {
                sale_check_id: null,
                start_time: '',
                end_time: '',
                is_for_all: false,
                bant_id: null,
                bant: null,
                sale_product_process_id: null,
                sale_product_process: null,
                comment: '',
            },
            saleProductProcessDrawer: false,
            bantDrawer: false,
            direction: "btt",
            loading: false,
        };
    },
    methods: {
        ...mapActions({
            createSaleDisruption: 'saleChecks/createSaleDisruption',
        }),
        async afterOpen() {
            this.form = {
                sale_check_id: this.sale_check_id ? this.sale_check_id : null,
                start_time: '',
                end_time: '',
                is_for_all: false,
                bant_id: null,
                sale_product_process_id: null,
                comment: '',
            };
        },
        submit() {
            if (!this.loading) {
                this.loading = true;
                this.createSaleDisruption(this.form)
                    .then(res => {
                        this.$alert(res);
                        this.loading = false;
                        this.close();
                    })
                    .catch(err => {
                        this.loading = false;
                        this.$alert(err);
                    })
            }            
        },
        setSaleProductProcess(data) {
            if (data) {
                this.form.sale_product_process_id = data.sale_product_process_id;
                this.form.sale_product_process = data.sale_product_process;
            }
        },
        setBant(data) {
            if (data) {
                this.form.bant_id = data.bant_id;
                this.form.bant = data.bant;
            }
        },

        closeDrawer(drawer) {
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].closed)) {
                    this.$refs[ref].closed()
                }
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        afterLeave() {
            this.form = {
                sale_check_id: null,
                start_time: '',
                end_time: '',
                is_for_all: false,
                bant_id: null,
                sale_product_process_id: null,
                comment: '',
            };
        }
    },
};
</script>
<style>
.my-time-full {
    display: flex;
    justify-content: center;
    padding-top: 50px;
}
.my-time-full .el-date-editor.el-input {
    width: 600px;
}
.my-time input {
    height: 80px;
    width: 600px;
    margin: auto;
    font-size: 25px;
}
</style>