<template>
    <div class="modal-f-t">
        <div class="itme-scroll flex-1">
            <div v-for="(bant, index) in bants" :key="'bants-' + index" class="nav-meni-itmes-modal text-center">
                <el-radio v-model="radio" :label="bant.id">
                    {{ bant.name }}
                </el-radio>
            </div>
        </div>

        <el-row class="btns">
            <el-button @click="submit()" type="success" plain>{{ $t('message.save') }}</el-button>
            <el-button @click="close()"  type="warning" plain>{{ $t('message.close') }}</el-button>
        </el-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import drawer from '@/utils/mixins/includes/drawer';

export default {
    mixins: [drawer],
    props:['bant_id'],
    data() {
        return {
            radio: '',
        };
    },
    computed: {
        ...mapGetters({
            bants: 'saleChecks/bant_inventory'
        })
    },
    methods: {
        ...mapActions({
            loadInventory: "saleChecks/getBantInventory",
        }),
        async afterOpen() {
            this.radio = this.bant_id ? this.bant_id : null;
            if(!_.size(this.bants)) await this.loadInventory();
        },
        submit() {
            if (!this.radio) {
                this.$message({
                    message: this.$t('message.Not selected'),
                    type: 'warning',
                    showClose: true
                });
                return;
            }

            let bant = _.find(this.bants, ['id', this.radio]);
            this.$emit('set-bant', {
                bant_id: this.radio,
                bant: bant,
            })
            this.close();
        }
    },
};
</script>