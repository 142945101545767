<template>
    <div class="vw2">
        <div class="top-icon font12">
            <router-link :to="{ name: 'MainLayoutHome' }" class="bg-2">
                <i class="el-icon-arrow-left"></i> {{ $t('message.Back') }}
            </router-link>
            <span>{{ $t('message.REFA') }}</span>            
           
            <div style="margin-left: -90px;">
                <el-button @click="openSaleDisruptionDrawer()" type="warning" class="el-icon-warning-outline color-pora">
                    {{ $t('message.Sale disruption') }}
                </el-button>
                <el-button @click="logout()" type="danger" class="el-icon-unlock color-pora">
                    {{ $t('message.Exit') }}
                </el-button>
            </div>
        </div>
        <div class="my-tabs-card new-tabs">
            <el-tabs v-model="activeName" type="card" :before-leave="beforeLeaveTab">
                <el-tab-pane name="first" :label="$t('message.Working shift')">
                    <div class="nav-meni-itmes" @click="workingShiftDrawer = true">
                        <i class="el-icon-document left-icon"></i>
                        <div class="name-p">
                            <div class="t-name">{{ form_initial_data.working_shift ? (form_initial_data.working_shift.name + ' (' + form_initial_data.working_shift.start_time + ' - ' + form_initial_data.working_shift.end_time + ')') : $t('message.Not selected') }}</div>
                            <div class="b-name">{{ $t('message.Working shift') }}</div>
                        </div>
                        <i class="el-icon-arrow-right right-icon"></i>
                    </div>
                    <div class="d-flex">
                        <div
                            class="nav-meni-itmes flex-1"
                            @click="saleDrawer = true"
                        >
                            <i class="el-icon-setting left-icon"></i>
                            <div class="name-p">
                                <div class="t-name">{{ form_initial_data.sale_id ? ($t('message.sale') + ' № ' + form_initial_data.sale_id) : $t('message.Not selected') }}</div>
                                <div class="b-name">{{ $t('message.sale') }}:</div>
                            </div>
                        </div>
                        <div class="nav-meni-itmes ml-30">
                            <div @click="saleScanDrawer = true">
                                <img
                                    class="scan-img"
                                    src="./../../../public/img/qr-code-scan.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div
                            class="nav-meni-itmes flex-1"
                            @click="saleProductDrawer = true"
                        >
                            <i class="el-icon-setting left-icon"></i>
                            <div class="name-p">
                                <div class="t-name">{{ (form_initial_data.sale_product && form_initial_data.sale_product.product) ? form_initial_data.sale_product.product.name : $t('message.Not selected') }}</div>
                                <div class="b-name">{{ $t('message.product') }}:</div>
                            </div>
                        </div>
                        <div class="nav-meni-itmes ml-30">
                            <div @click="saleProductScanDrawer = true">
                                <img
                                    class="scan-img"
                                    src="./../../../public/img/qr-code-scan.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div>
                        <div class="text-center">
                            <el-button @click="submitInitialDataForCheck()" :loading="loading" type="success" class="btn-100 btn-save">
                                {{ $t('message.save') }}
                            </el-button>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="second" :label="$t('message.Result')">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div class="nav-meni-itmes" @click="saleProductProcessDrawer = true">
                                <i class="el-icon-sort left-icon"></i>
                                <div class="name-p">
                                    <div class="t-name">{{ (form.sale_product_process && form.sale_product_process.process) ? form.sale_product_process.process.name : $t('message.Not selected') }}</div>
                                    <div class="b-name">{{ $t('message.Process') }}:</div>
                                </div>
                                <i class="el-icon-arrow-right right-icon"></i>
                            </div>
                            
                            <div v-if="form.is_first_check_of_process" class="nav-meni-itmes" @click="startTimeDrawer = true">
                                <i class="el-icon-time left-icon"></i>
                                <div class="name-p">
                                    <div class="t-name">{{ form.start_time ? form.start_time : $t('message.Not selected') }}</div>
                                    <div class="b-name">{{ $t('message.Start time of work') }}:</div>
                                </div>
                                <i class="el-icon-arrow-right right-icon"></i>
                            </div>

                            <div v-else class="nav-meni-itmes">
                                <i class="el-icon-time left-icon"></i>
                                <div class="name-p">
                                    <div class="t-name">{{ (last_check && last_check.check_time) ? (last_check.check_time.name + ' (' + last_check.check_time.start_time + ' - ' + last_check.check_time.end_time + ') ' + $t('message.at') + ' ' + last_check.created_at) : '' }}</div>
                                    <div class="b-name">{{ $t('message.Last check') }}:</div>
                                </div>
                                <!-- <i class="el-icon-arrow-right right-icon"></i> -->
                            </div>

                            <div class="nav-meni-itmes" @click="checkTimeDrawer = true">
                                <i class="el-icon-odometer left-icon"></i>
                                <div class="name-p">
                                    <div class="t-name">
                                        {{ (form && form.check_time) ? (form.check_time.name + ' (' + form.check_time.start_time + ' - ' + form.check_time.end_time + ')') : $t('message.Not selected') }}
                                    </div>
                                    <div class="b-name">{{ $t('message.Check') }}:</div>
                                </div>
                                <i class="el-icon-arrow-right right-icon"></i>
                            </div>

                            <div v-for="(employee, index) in form.sale_check_employees" :key="'form-sale_check_employees' + index" class="my-ikklik">
                                <div @click="openEmployeeScan(index)" class="d-flex mr-60">
                                    <div
                                        class="nav-meni-itmes flex-1"
                                    >
                                        <i class="el-icon-setting left-icon"></i>
                                        <div class="name-p">
                                            <div class="t-name">{{ employee.employee ? employee.employee.name : $t('message.Not selected') }}</div>
                                            <div class="b-name">{{ $t('message.Employee') }}</div>
                                        </div>
                                    </div>
                                    <div class="nav-meni-itmes ml-30">
                                        <div>
                                            <img
                                                class="scan-img"
                                                src="./../../../public/img/qr-code-scan.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div @click="openMachineScan(index)" class="d-flex mr-60">
                                    <div
                                        class="nav-meni-itmes flex-1"
                                    >
                                        <i class="el-icon-setting left-icon"></i>
                                        <div class="name-p">
                                            <div class="t-name">{{ employee.machine ? (employee.machine.name + ' (' + employee.machine.code + ')') : $t('message.Not selected') }} | {{ employee.bant  ? employee.bant.name : '' }}</div>
                                            <div class="b-name">{{ $t('message.Machine') }} | {{ $t('message.Bant') }}</div>
                                        </div>
                                    </div>
                                    <div class="nav-meni-itmes ml-30">
                                        <div>
                                            <img
                                                class="scan-img"
                                                src="./../../../public/img/qr-code-scan.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <el-button @click="removeEmployeeAndMachine(index)" type="danger" icon="el-icon-delete" ></el-button>
                                </div>
                            </div>

                            <el-button class="btn-save2" @click="addEmployeeAndMachine()" type="success" plain>{{ $t('message.Add employee') }}</el-button>
                            
                            <div class="nav-meni-itmes" @click="metoNumbersInputDrawer = true">
                                <i class="el-icon-refresh left-icon"></i>
                                <div class="name-p">
                                    <div class="t-name">
                                        <span v-for="(meto_number, index) in form.meto_numbers" :key="'meto_numbers-' + index">
                                            <template v-if="index == 0">{{ (index + 1) + ') ' + meto_number.current_meto_number }}</template>
                                            <template v-else>{{ ', ' + (index + 1) + ') ' + meto_number.current_meto_number }}</template>
                                        </span>
                                    </div>
                                    <div class="b-name">{{ $t('message.METO') }}:</div>
                                </div>
                                <i class="el-icon-arrow-right right-icon"></i>
                            </div>

                            <!-- // TODO:: olib tashash -->
                            <div class="nav-meni-itmes">
                                <el-date-picker
                                    prefix-icon="el-icon-date"
                                    class="my-time"
                                    v-model="form.measurement_time"
                                    type="datetime"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :placeholder="$t('message.Measurement time')">
                                </el-date-picker>
                            </div>
                        </el-col>
                        <!-- end  -->
                        <el-col class="refa-history" :span="12">
                            <template v-if="form.sale_check_employees.length == 1">
                                <div v-for="(item, index2) in form.sale_check_employees[0].sale_check_times_history" :key="index2 + 'sale_check_times_history'" :class="getClassItem(item)">
                                    <div class="itme-r-u">
                                        <span>{{ $t('message.Working shift') }}:</span>
                                        <b>{{ getWorkingShiftName(item) }}</b>
                                    </div>
                                    <div class="itme-r-u">
                                        <span>{{ $t('message.sale') + ' №' }}:</span>
                                        <b>{{ (item.sale_check_process && item.sale_check_process.sale_check) ? item.sale_check_process.sale_check.sale_id : '' }}</b>
                                    </div>
                                    <div class="itme-r-u">
                                        <span>{{ $t('message.product') }}:</span>
                                        <b>{{ (item.sale_check_process && item.sale_check_process.sale_check && item.sale_check_process.sale_check.sale_product && item.sale_check_process.sale_check.sale_product.product) ? item.sale_check_process.sale_check.sale_product.product.name : '' }}</b>
                                    </div>
                                    <div class="itme-r-u">
                                        <span>{{ $t('message.Process') }}:</span>
                                        <b>{{ (item.sale_check_process && item.sale_check_process.sale_product_process && item.sale_check_process.sale_product_process.process) ? item.sale_check_process.sale_product_process.process.name : '' }}</b>
                                    </div>
                                    <div class="itme-r-u">
                                        <span>{{ $t('message.Check') }}:</span>
                                        <b>{{ (item.check_time) ? (item.check_time.name + ' (' + item.check_time.start_time + ' - ' + item.check_time.end_time + ')') : '' }}</b>
                                    </div>
                                    <div class="itme-r-u">
                                        <span>{{ $t('message.Bant') }}:</span>
                                        <b>{{ (item.sale_check_process && item.sale_check_process.bant) ? item.sale_check_process.bant.name : '' }}</b>
                                    </div>
                                    <div class="itme-r-u">
                                        <span>{{ $t('message.Employee') }}:</span>
                                        <b>{{ getEmployeeNames(item) }}</b>
                                    </div>
                                    <div class="itme-r-u">
                                        <span>{{ $t('message.Machine') }}:</span>
                                        <b>{{ getMachineNames(item) }}</b>
                                    </div>
                                    <div class="itme-r-u">
                                        <span>{{ $t('message.Measurement time') }}:</span>
                                        <b>{{ item.created_at }}</b>
                                    </div>
                                    <div class="itme-r-u">
                                        <span>{{ $t('message.METO') + ' №' }}:</span>
                                        <b>{{ getMetoNumbers(item) }}</b>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="form.sale_check_employees.length > 1">
                                <el-tabs v-model="activeNameRefaHistory">
                                    <el-tab-pane v-for="(sale_check_employee_item, index) in form.sale_check_employees" :key="index + '-refa-history-tab'" :label="sale_check_employee_item.employee ? sale_check_employee_item.employee.name : $t('message.Not selected')" :name="index + '-name'">
                                        <div v-for="(item, index2) in sale_check_employee_item.sale_check_times_history" :key="index2 + 'sale_check_times_history'" :class="getClassItem(item)">
                                            <div class="itme-r-u">
                                                <span>{{ $t('message.Working shift') }}:</span>
                                                <b>{{ getWorkingShiftName(item) }}</b>
                                            </div>
                                            <div class="itme-r-u">
                                                <span>{{ $t('message.sale') + ' №' }}:</span>
                                                <b>{{ (item.sale_check_process && item.sale_check_process.sale_check) ? item.sale_check_process.sale_check.sale_id : '' }}</b>
                                            </div>
                                            <div class="itme-r-u">
                                                <span>{{ $t('message.product') }}:</span>
                                                <b>{{ (item.sale_check_process && item.sale_check_process.sale_check && item.sale_check_process.sale_check.sale_product && item.sale_check_process.sale_check.sale_product.product) ? item.sale_check_process.sale_check.sale_product.product.name : '' }}</b>
                                            </div>
                                            <div class="itme-r-u">
                                                <span>{{ $t('message.Process') }}:</span>
                                                <b>{{ (item.sale_check_process && item.sale_check_process.sale_product_process && item.sale_check_process.sale_product_process.process) ? item.sale_check_process.sale_product_process.process.name : '' }}</b>
                                            </div>
                                            <div class="itme-r-u">
                                                <span>{{ $t('message.Check') }}:</span>
                                                <b>{{ (item.check_time) ? (item.check_time.name + ' (' + item.check_time.start_time + ' - ' + item.check_time.end_time + ')') : '' }}</b>
                                            </div>
                                            <div class="itme-r-u">
                                                <span>{{ $t('message.Bant') }}:</span>
                                                <b>{{ (item.sale_check_process && item.sale_check_process.bant) ? item.sale_check_process.bant.name : '' }}</b>
                                            </div>
                                            <div class="itme-r-u">
                                                <span>{{ $t('message.Employee') }}:</span>
                                                <b>{{ getEmployeeNames(item) }}</b>
                                            </div>
                                            <div class="itme-r-u">
                                                <span>{{ $t('message.Machine') }}:</span>
                                                <b>{{ getMachineNames(item) }}</b>
                                            </div>
                                            <div class="itme-r-u">
                                                <span>{{ $t('message.Measurement time') }}:</span>
                                                <b>{{ item.created_at }}</b>
                                            </div>
                                            <div class="itme-r-u">
                                                <span>{{ $t('message.METO') + ' №' }}:</span>
                                                <b>{{ getMetoNumbers(item) }}</b>
                                            </div>
                                        </div>
                                    </el-tab-pane>
                                </el-tabs>    
                            </template>
                            
                        </el-col>
                    </el-row>

                    <div>
                        <div class="text-center">
                            <el-button @click="submit()" :loading="loading" type="success" class="btn-100 btn-save">
                                {{ $t('message.save') }}
                            </el-button>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>

        <!-- Modals  -->
        <el-drawer
            class="modal-style"
            :direction="direction"
            :visible.sync="workingShiftDrawer"
            size="80%"
            ref="workingShiftDrawer" 
            @closed="drawerClosed('workingShiftDrawerChild')" 
            @opened="drawerOpened('workingShiftDrawerChild')"
        >
            <working-shift-select 
                :working_shift_id="form_initial_data.working_shift_id"
                @set-working-shift="setWorkingShift"
                drawer="workingShiftDrawer" 
                ref="workingShiftDrawerChild">
            </working-shift-select>
        </el-drawer>

        <el-drawer
            class="modal-style"
            :direction="direction"
            :visible.sync="saleDrawer"
            size="80%"
            ref="saleDrawer" 
            @closed="drawerClosed('saleDrawerChild')" 
            @opened="drawerOpened('saleDrawerChild')"
        >
            <sale-select
                :sale_id="form_initial_data.sale_id"
                @set-sale="setSale"
                drawer="saleDrawer" 
                ref="saleDrawerChild">
            </sale-select>
        </el-drawer>

        <el-drawer
            :with-header="false"
            class=""
            :show-close="false"
            :direction="direction"
            :visible.sync="saleScanDrawer"
            size="100%"
            ref="saleScanDrawer" 
            @closed="drawerClosed('saleScanDrawerChild')" 
            @opened="drawerOpened('saleScanDrawerChild')"
        >
            <sale-scan                
                @set-sale="setSale"
                drawer="saleScanDrawer" 
                ref="saleScanDrawerChild">
            </sale-scan>
        </el-drawer>

        <el-drawer
            class="modal-style"
            :direction="direction"
            :visible.sync="saleProductDrawer"
            size="80%"
            ref="saleProductDrawer"
            @closed="drawerClosed('saleProductDrawerChild')" 
            @opened="drawerOpened('saleProductDrawerChild')"
        >
            <sale-product-select
                :sale_id="form_initial_data.sale_id"
                :sale_product_id="form_initial_data.sale_product_id"
                @set-sale-product="setSaleProduct"
                drawer="saleProductDrawer" 
                ref="saleProductDrawerChild">
            </sale-product-select>
        </el-drawer>

        <el-drawer
            :with-header="false"
            class=""
            :show-close="false"
            :direction="direction"
            :visible.sync="saleProductScanDrawer"
            size="100%"
            ref="saleProductScanDrawer" 
            @closed="drawerClosed('saleProductScanDrawerChild')" 
            @opened="drawerOpened('saleProductScanDrawerChild')"
        >
            <sale-product-scan
                :sale_id="form_initial_data.sale_id"                
                @set-sale-product="setSaleProduct"
                drawer="saleProductScanDrawer" 
                ref="saleProductScanDrawerChild">
            </sale-product-scan>
        </el-drawer>

        <el-drawer
            class="modal-style"
            :direction="direction"
            :visible.sync="saleProductProcessDrawer"
            size="80%"
            ref="saleProductProcessDrawer"
            @closed="drawerClosed('saleProductProcessDrawerChild')" 
            @opened="drawerOpened('saleProductProcessDrawerChild')"
        >
            <sale-product-process-select
                :sale_product_id="form_initial_data.sale_product_id"
                :sale_product_process_id="form.sale_product_process_id"
                @set-sale-product-process="setSaleProductProcess"
                drawer="saleProductProcessDrawer" 
                ref="saleProductProcessDrawerChild">
            </sale-product-process-select>
        </el-drawer>

        <el-drawer
            class="modal-style"
            :direction="direction"
            :visible.sync="startTimeDrawer"
            size="80%"
            ref="startTimeDrawer"
            @closed="drawerClosed('startTimeDrawerChild')" 
            @opened="drawerOpened('startTimeDrawerChild')"
        >
            <start-time
                :start_time="form.start_time"
                @set-start-time="setStartTime"
                drawer="startTimeDrawer" 
                ref="startTimeDrawerChild">
                </start-time>
        </el-drawer>

        <el-drawer
            class="modal-style"
            :direction="direction"
            :visible.sync="checkTimeDrawer"
            size="80%"
            ref="checkTimeDrawer"
            @closed="drawerClosed('checkTimeDrawerChild')" 
            @opened="drawerOpened('checkTimeDrawerChild')"
        >
            <check-time-select
                :check_time_id="form.check_time_id"
                @set-check-time="setCheckTime"
                drawer="checkTimeDrawer" 
                ref="checkTimeDrawerChild">

            </check-time-select>
        </el-drawer>

        <el-drawer
             :with-header="false"
            class=""
            :show-close="false"
            :direction="direction"
            :visible.sync="employeeScanDrawer"
            size="100%"
            ref="employeeScanDrawer" 
            @closed="drawerClosed('employeeScanDrawerChild')" 
            @opened="drawerOpened('employeeScanDrawerChild')"
        >
            <employee-scan
                :employee_item_key="selectedEmployeeKey"                
                @set-employee="setEmployeeScan"
                drawer="employeeScanDrawer" 
                ref="employeeScanDrawerChild">
            </employee-scan>
        </el-drawer>

        <el-drawer
             :with-header="false"
            class=""
            :show-close="false"
            :direction="direction"
            :visible.sync="machineScanDrawer"
            size="100%"
            ref="machineScanDrawer" 
            @closed="drawerClosed('machineScanDrawerChild')" 
            @opened="drawerOpened('machineScanDrawerChild')"
        >
            <machine-scan
                :employee_item_key="selectedEmployeeKey"                
                @set-machine="setMachineScan"
                drawer="machineScanDrawer" 
                ref="machineScanDrawerChild">
            </machine-scan>
        </el-drawer>
        
        <el-drawer
            class="modal-style"
            :direction="direction"
            :visible.sync="metoNumbersInputDrawer"
            size="80%"
            ref="metoNumbersInputDrawer" 
            @closed="drawerClosed('metoNumbersInputDrawerChild')" 
            @opened="drawerOpened('metoNumbersInputDrawerChild')"
        >
            <meto-numbers-input
                :meto_numbers="form.meto_numbers"
                :bant_id="form.bant_id"
                :sale_product_process_id="form.sale_product_process_id"
                :form_initial_data="form_initial_data"
                @set-meto-numbers="setMetoNumbers"
                drawer="metoNumbersInputDrawer" 
                ref="metoNumbersInputDrawerChild">
            </meto-numbers-input>
        </el-drawer>

        
        <el-drawer
            class="modal-style"
            :show-close="false"
            :direction="direction"
            :visible.sync="saleDisruptionDrawer"
            size="95%"
            ref="saleDisruptionDrawer" 
            @closed="drawerClosed('saleDisruptionDrawerChild')" 
            @opened="drawerOpened('saleDisruptionDrawerChild')"
        >
            <sale-disruption
                :sale_check_id="form.sale_check_id"
                :sale_product_id="form_initial_data.sale_product_id"
                drawer="saleDisruptionDrawer" 
                ref="saleDisruptionDrawerChild">
            </sale-disruption>
        </el-drawer>
    </div>
</template>
<script>
import WorkingShiftSelect from "./components/working-shift-select.vue";
import SaleSelect from "./components/sale-select.vue";
import SaleScan from "./components/sale-scan.vue";
import SaleProductSelect from "./components/sale-product-select.vue";
import SaleProductScan from "./components/sale-product-scan.vue";
import SaleProductProcessSelect from "./components/sale-product-process-select.vue";
import CheckTimeSelect from "./components/check-time-select.vue";
import EmployeeScan from "./components/employee-scan.vue";
import MachineScan from "./components/machine-scan.vue";
import MetoNumbersInput from "./components/meto-numbers-input.vue";
import StartTime from "./components/start-time.vue";
import SaleDisruption from "./components/sale-disruption.vue";

import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        WorkingShiftSelect,
        SaleSelect,
        SaleScan,
        SaleProductSelect,
        SaleProductScan,
        SaleProductProcessSelect,
        StartTime,
        CheckTimeSelect,
        EmployeeScan,
        MachineScan,
        MetoNumbersInput,
        SaleDisruption,
    },
    data() {
        return {
            form_initial_data: {},
            form: {},
            activeName: "first",
            workingShiftDrawer: false,
            saleDrawer: false,
            saleScanDrawer: false,
            saleProductDrawer: false,
            saleProductScanDrawer: false,
            saleProductProcessDrawer: false,
            startTimeDrawer: false,
            checkTimeDrawer: false,
            employeeScanDrawer: false,
            machineScanDrawer: false,
            metoNumbersInputDrawer: false,
            saleDisruptionDrawer: false,
            selectedEmployeeKey: null,
            last_check: null,
            loading: false,
            
            activeNameRefaHistory: '0-name',
            direction: "btt",
        };
    },
    created() {
        this.activeName = 'first';
        this.activeNameRefaHistory = '0-name';
        this.form_initial_data = JSON.parse(JSON.stringify(this.getFormInitialData));
        this.form = JSON.parse(JSON.stringify(this.getForm));
        this.loadInitialDataForCheck();
    },
    computed: {
        ...mapGetters({            
            getFormInitialData: 'saleChecks/form_initial_data',
            getForm: 'saleChecks/form',
        }),
    },
    methods: {
        ...mapActions({
            save: "saleChecks/store",
            getInitialDataForCheck: "saleChecks/getInitialDataForCheck",
            createInitialData: "saleChecks/createInitialData",
            checkIsFirstCheckOfProcess: "saleChecks/checkIsFirstCheckOfProcess",
            getSaleCheckTimesHistoryOfEmployee: "saleChecks/getSaleCheckTimesHistoryOfEmployee",
        }),
        loadInitialDataForCheck() {
            this.getInitialDataForCheck()
                .then(res => {
                    let sale_check = res.data.sale_check;
                    if (sale_check) {
                        this.form_initial_data.working_shift = sale_check.working_shift;
                        this.form_initial_data.working_shift_id = sale_check.working_shift_id;
                        this.form_initial_data.sale = sale_check.sale;
                        this.form_initial_data.sale_id = sale_check.sale_id;
                        this.form_initial_data.sale_product = sale_check.sale_product;
                        this.form_initial_data.sale_product_id = sale_check.sale_product_id;

                        this.form.sale_check_id = sale_check.id;
                    }
                }).catch(err => {

                });
        },
        submitInitialDataForCheck() {
            if (!this.loading) {
                this.loading = true;
                this.createInitialData(this.form_initial_data)
                    .then(res => {
                        let sale_check = res.data.sale_check;
                        this.form.sale_check_id = sale_check.id;
                        this.$alert(res);
                        this.loading = false;
                    })
                    .catch(err => {
                        this.loading = false;
                        this.$alert(err);
                    })
            }
        },
        submit() {
            if (!this.loading) {
                this.loading = true;
                this.save(this.form)
                    .then(res => {
                        // soxranitdan keyin keyingi proverka uchun danniyni tayyorlash
                        let sale_check_id = this.form.sale_check_id;                        
                        let check_time_id = this.form.check_time_id;
                        let check_time = this.form.check_time;
                        this.form = JSON.parse(JSON.stringify(this.getForm)); // shu yerda this.form.sale_check_id tozalanadi. shuning uchun eski qiymat olinib boshqattan set qilinvotti
                        this.form.sale_check_id = sale_check_id;
                        this.form.check_time_id = check_time_id;
                        this.form.check_time = check_time;
                        this.last_check = null;
                        this.$alert(res);
                        this.loading = false;
                    })
                    .catch(err => {
                        this.loading = false;
                        this.$alert(err);
                    })
            }
        },
        setWorkingShift(data) {
            if (data) {
                this.form_initial_data.working_shift_id = data.working_shift_id;
                this.form_initial_data.working_shift = data.working_shift;
            }
        },
        setSale(data) {
            if (data) {
                // agar zayavka ozgartirilayotgan bolsa produktni va processni tozalash
                if (this.form_initial_data.sale_id != data.sale_id) {
                    this.form_initial_data.sale_product_id = null;
                    this.form_initial_data.sale_product = null;

                    this.form.sale_product_process_id = null;
                    this.form.sale_product_process = null;
                }

                this.form_initial_data.sale_id = data.sale_id;
                this.form_initial_data.sale = data.sale;
            }
        },
        setSaleProduct(data) {
            if (data) {
                // agar sale_product ozgartirilayotgan bolsa processni tozalash                
                if (this.form_initial_data.sale_product_id != data.sale_product_id) {
                    this.form.sale_product_process_id = null;
                    this.form.sale_product_process = null;

                    this.last_check = null;
                }

                this.form_initial_data.sale_product_id = data.sale_product_id;
                this.form_initial_data.sale_product = data.sale_product;
            }
        },
        setSaleProductProcess(data) {
            if (data) {
                this.form.sale_product_process_id = data.sale_product_process_id;
                this.form.sale_product_process = data.sale_product_process;
                
                let params = {
                    sale_check_id: this.form.sale_check_id,
                    sale_product_process_id: this.form.sale_product_process_id,
                }
                this.checkIsFirstCheckOfProcess(params)
                    .then(res => {
                        let sale_check_process = res.data.sale_check_process;
                        this.last_check = res.data.last_check;
                        if (sale_check_process) {
                            this.form.is_first_check_of_process = false;
                        }
                        else {
                            // birinchi proverka bolsa
                            this.form.is_first_check_of_process = true;
                        }
                    }).catch(err => {
                        this.$alert(err);
                    });
            }
        },
        setStartTime(data) {
            if (data) {
                this.form.start_time = data.start_time;
            }
        },
        setCheckTime(data) {
            if (data) {
                this.form.check_time_id = data.check_time_id;
                this.form.check_time = data.check_time;
            }
        },
        openEmployeeScan(key) {
            this.selectedEmployeeKey = key;
            this.employeeScanDrawer = true;
        },
        setEmployeeScan(data) {
            if (data) {
                if (this.form.sale_check_employees[data.employee_item_key]) {
                    this.form.sale_check_employees[data.employee_item_key].employee_id = data.employee_id;
                    this.form.sale_check_employees[data.employee_item_key].employee = data.employee;

                    let params = {
                        sale_check_id: this.form.sale_check_id,
                        employee_id: data.employee_id,
                    }
                    this.getSaleCheckTimesHistoryOfEmployee(params).then(res => {
                        this.form.sale_check_employees[data.employee_item_key].sale_check_times_history = res.data.sale_check_times;
                    }).catch(err => {
                        this.$alert(err);
                    });
                }
            }
        },
        openMachineScan(key) {
            this.selectedEmployeeKey = key;
            this.machineScanDrawer = true;
        },
        setMachineScan(data) {
            if (data) {
                // agar endi machina qoshayotgan bolsa hamma malumotni biriktirish
                if (this.form.sale_check_employees[data.employee_item_key]) {
                    if (_.size(this.form.sale_check_employees) == 1) {
                        this.form.sale_check_employees[data.employee_item_key].machine_id = data.machine_id;
                        this.form.sale_check_employees[data.employee_item_key].machine = data.machine;
                        this.form.sale_check_employees[data.employee_item_key].bant_id = data.bant_id;
                        this.form.sale_check_employees[data.employee_item_key].bant = data.bant;

                        this.form.bant_id = data.bant_id;
                    }
                    // agar 2 yoki undan ortiq machinalar biriktirilgan bolsa, bantlari birxil ekanligini tekshirish
                    else if (_.size(this.form.sale_check_employees) > 1) {
                        let item_has_not_match = _.find(this.form.sale_check_employees, function(o) {
                            return o.bant_id && o.bant_id != data.bant_id;
                        });

                        // item_has_not_match bor dgani boshqa itemlarni bant boshqacha dgani
                        if (item_has_not_match) {
                            this.$message({
                                message: this.$t('message.Bant of machines does not match'),
                                type: 'warning',
                                showClose: true
                            });
                        }
                        else {
                            this.form.sale_check_employees[data.employee_item_key].machine_id = data.machine_id;
                            this.form.sale_check_employees[data.employee_item_key].machine = data.machine;
                            this.form.sale_check_employees[data.employee_item_key].bant_id = data.bant_id;
                            this.form.sale_check_employees[data.employee_item_key].bant = data.bant;

                            this.form.bant_id = data.bant_id;
                        }
                    }
                }
            }
        },
        addEmployeeAndMachine() {
            this.form.sale_check_employees.push({
                employee_id: null,
                employee: null,
                machine_id: null,
                machine: null,
                bant_id: null,
                bant: null,
                sale_check_times_history: [],
            });
        },
        removeEmployeeAndMachine(index) {
            if (_.size(this.form.sale_check_employees)) this.form.sale_check_employees.splice(index, 1)
        },
        setMetoNumbers(data) {
            if (data) {
                this.form.meto_numbers = JSON.parse(JSON.stringify(data.meto_numbers));
            }
        },
        beforeLeaveTab(newVal) {
            if (newVal == 'second') {
                if (!this.form.sale_check_id) {
                    this.$message({
                        message: this.$t('message.Required data not selected or not saved'),
                        type: 'warning',
                        showClose: true
                    });

                    return false;
                }
            }
        },

        openSaleDisruptionDrawer() {
            if (!this.form.sale_check_id) {
                this.$message({
                    message: this.$t('message.Required data not selected or not saved'),
                    type: 'warning',
                    showClose: true
                });

                return false;
            }

            this.saleDisruptionDrawer = true;
        },        

        /* Metods for History */
        getClassItem(item) {
            let norm_per_hour = 0;
            if (item.sale_check_process && item.sale_check_process.sale_product_process) {
                // processni qancha vaqtda nechta chiqara olishi (minutda)
                let process_spent_time = item.sale_check_process.sale_product_process.process_spent_time;
                let production_count = item.sale_check_process.sale_product_process.production_count;

                // 1 soatda yani 60 minutda qancha tayorlash normasini topish
                norm_per_hour = (process_spent_time > 0) ? _.round(60 * production_count / process_spent_time) : 0;
            }

            let rating = 0;
            let rating_percent = (norm_per_hour > 0) ? (item.quantity_by_norm * 100 / norm_per_hour) : 0;
            if (rating_percent < 71) {
                rating = 3;
            }
            else if (rating_percent >= 72 && rating_percent < 86) {
                rating = 4;
            }
            else if (rating_percent >= 86) {
                rating = 5;
            }

            let class_name = 'rght-user-info-re ';

            switch(rating) {
                case 3:
                    class_name += 'bed';
                    break;
                case 4:
                    class_name += 'average';
                    break;
                case 5:
                    class_name += 'good';
                    break;
            }

            return class_name;
        },
        getWorkingShiftName(item) {
            let working_shift = (item.sale_check_process && item.sale_check_process.sale_check && item.sale_check_process.sale_check.working_shift) ? item.sale_check_process.sale_check.working_shift : null;

            if (working_shift) {
                return (working_shift.name + ' (' + working_shift.start_time + ' - ' + working_shift.end_time + ')');
            }
            else {
                return ''
            }
        },
        getEmployeeNames(item) {
            var result = '';
            _.forEach(item.sale_check_employees, function(item2, key) {
                let employee_name = (item2.employee) ? item2.employee.name : '';
                if (key === 0) {
                    result += employee_name;
                }
                else {
                    result += (', ' + employee_name);
                }
            });

            return result;
        },
        getMachineNames(item) {
            var result = '';
            _.forEach(item.sale_check_employees, function(item2, key) {
                let machine_name = (item2.machine) ? (item2.machine.name + ' (' + item2.machine.code + ')') : '';
                if (key === 0) {
                    result += machine_name;
                }
                else {
                    result += (', ' + machine_name);
                }
            });

            return result;
        },
        getMetoNumbers(item) {
            var result = '';
            _.forEach(item.meto_numbers, function(item2, key) {
                if (key === 0) {
                    result += item2.current_meto_number;
                }
                else {
                    result += (', ' + item2.current_meto_number);
                }
            });

            return result;
        },
        /* Metods for History */

        closeDrawer(drawer) {
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].closed)) {
                    this.$refs[ref].closed()
                }
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        logout() {
            this.$confirm('Вы уверены, что хотите выйти?', 'Подтверждение', {
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Нет',
                    type: 'warning'
                }).then(async () => {
                    await this.$store.dispatch("auth/logout");
                    this.$router.push(`/login?redirect=${this.$route.fullPath}`);
                }).catch(() => {
                
                });
        },
    },
};
</script>